* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.bell-icon__wrapper {
    height: 0;
    padding-top: 50%;
}

.calendar_wrapper {
    max-width: 80%;

    @media (max-width: 768px) {
        max-width: 100%;
    }

    .fc {
        width: 100%;
        font-family: Roboto;

        .fc-header-toolbar {
            .fc-toolbar-chunk {
                .fc-toolbar-title {
                    text-transform: capitalize;
                    font-family: Roboto;

                    @media all and (max-width: 768px) {
                        font-size: 1.25rem !important;
                    }
                }

                .fc-button-group {}

                .fc-button {
                    background-color: #1565c0;
                    border-color: #1565c0;
                    font-family: Roboto;
                }
            }
        }

        .fc-view-harness {
            .fc-daygrid-day-top {}
        }

        .fc-event-time {
            display: none !important;
        }

        @media screen and (max-width: 480px) {
            .fc-event-title {
                height: 22px;
                font-size: 12px !important;
                word-break: break-all;
                padding: 0;
            }

            .fc-button {
                line-height: 1 !important;
                font-size: 0.9em !important;
                padding: 5px !important;
            }

            .fc-newSchedule-button {
                line-height: 1.4 !important;
            }

            .fc-daygrid-event {
                white-space: normal;
            }

            .fc-daygrid-day-top {
                font-size: 12px !important;
            }

            .fc-view-harness {
                overflow: auto;

                .fc-scroller {
                    overflow: visible !important;
                }

                .fc-scrollgrid {
                    width: 800px !important;

                    .fc-scrollgrid-section {
                        .fc-col-header {
                            width: 800px !important;
                        }

                        .fc-daygrid-body {
                            width: 800px !important;

                            .fc-scrollgrid-sync-table {
                                width: 800px !important;
                            }
                        }
                    }
                }
            }

        }


    }
}
